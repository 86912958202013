import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

export const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  appID: process.env.REACT_APP_APP_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  auth: {
    email: process.env.REACT_APP_AUTH_EMAIL,
    password: process.env.REACT_APP_AUTH_PASSWORD
  }
}
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}
export const Auth = firebase.auth()
export const Firestore = firebase.firestore()
// firebase.firestore.setLogLevel("debug")
export const getUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = Auth.onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}
export default firebase