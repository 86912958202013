import React from "react";
import Carousel from "react-bootstrap/Carousel";
import shortid from "shortid";
import CamImage, { DefaultImage } from "./CamImage";
import ETABadge from "./ETABadge";
import TravelTimesChart from "./TravelTimesChart";

class CamImageCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: {},
    };
  }
  componentDidMount() {
    this.handleSelect(0);
  }
  onLoadImg = (e, i) => {
    if (e.target.complete) {
      let { loaded } = this.state;
      loaded[i] = true;
      this.setState({ loaded });
    }
  };
  handleSelect = (index) => {
    const { name } = this.props.location;
    this.props.ReactGA.event({
      category: "Carousel",
      action: `${name} cam ${index + 1}`,
    });
  };
  render() {
    const { id, location } = this.props;
    const badges = [
      {
        destination: "JB",
        orderClass: "one",
        duration:
          location.p1.dm && location.p1.dm.duration_in_traffic
            ? location.p1.dm.duration_in_traffic.text
            : "",
      },
      {
        destination: "SG",
        orderClass: "two",
        duration:
          location.p2.dm && location.p2.dm.duration_in_traffic
            ? location.p2.dm.duration_in_traffic.text
            : "",
      },
    ];
    return (
      <Carousel
        className="row image-container"
        controls={false}
        slide={true}
        touch={true}
        fade={true}
        interval={false}
        onSelect={this.handleSelect}
      >
        {location.source.length === 0 && (
          <Carousel.Item key={0}>
            <div className={`d-flex justify-content-center`}>
              <div className="spinner-border spinner-border-lg text-light">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            <div className="carousel-item-container lazyImage">
              <DefaultImage isLoading={true} />
            </div>
          </Carousel.Item>
        )}
        {location.source.map((s, i) => {
          const isLoading = this.state.loaded[i] ? false : true;
          return (
            <Carousel.Item key={i}>
              {!isLoading ? (
                ""
              ) : (
                <div className={`d-flex justify-content-center`}>
                  <div className="spinner-border spinner-border-lg text-light">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              <div className="carousel-item-container lazyImage">
                {!s.image || !isLoading ? (
                  ""
                ) : (
                  <DefaultImage isLoading={isLoading} />
                )}
                <CamImage
                  key={i}
                  index={i}
                  isLoading={isLoading}
                  isCamDown={s.camera_down}
                  onLoadImage={this.onLoadImg}
                  src={s.image}
                />

                {(!isLoading || !s.camera_down) &&
                  i === 0 &&
                  badges.map((badge, i) => (
                    <ETABadge
                      key={i}
                      id={id}
                      destination={badge.destination}
                      orderClass={badge.orderClass}
                      duration={badge.duration}
                    />
                  ))}
              </div>
            </Carousel.Item>
          );
        })}
        {location.p1.graph.length > 0 && (
          <Carousel.Item key={shortid.generate()}>
            <div className="carousel-item-container">
              <TravelTimesChart place={location.p1} />
            </div>
          </Carousel.Item>
        )}
        {location.p2.graph.length > 0 && (
          <Carousel.Item key={shortid.generate()}>
            <div className="carousel-item-container">
              <TravelTimesChart place={location.p2} />
            </div>
          </Carousel.Item>
        )}
      </Carousel>
    );
  }
}
export default CamImageCarousel;
