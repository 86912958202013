import React from 'react'
class FuelTable extends React.Component {
  clickSocial = (type) => {
    let href = ''
    const isMobile = this.props.a2hs.isMobile
    if (type === 'whatsapp') {
      href = "https://api.whatsapp.com/send?text=https://www.gojbleh.com"
    }
    if (type === 'facebook') {
      href = isMobile ? "fb-messenger://share/?link=https://www.gojbleh.com" :
        "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.gojbleh.com"
    }
    if (type === 'telegram') {
      href = "https://telegram.me/share/url?url=https://www.gojbleh.com"
    }
    const win = window.open(href, '_blank')
    win.focus()
    this.props.ReactGA.event({
      category: 'Share button',
      action: `Share ${type} ${isMobile ? 'native' : 'desktop'}`
    })
  }
  addToHomeScreen = () => {
    this.props.a2hs.addToHomeScreen()
    this.props.ReactGA.event({ category: 'A2HS button', action: `Create gojbleh shortcut` })
  }
  feedback = () => {
    this.props.ReactGA.event({ category: 'Feedback button', action: `Send feedback` })
    const win = window.open("https://forms.gle/8ygUqMHeyLm79XcQ9", '_blank')
    win.focus()
  }
  render() {
    // a2hs.isMobile ? "whatsapp://send?text=https://www.gojbleh.com" :
    // a2hs.isMobile ? "fb-messenger://share/?link=https://www.gojbleh.com" :
    // a2hs.isMobile ? "tg://msg_url?url=https://www.gojbleh.com" :
    const { fuelPrices, lastUpdate } = this.props
    return (
      <table className="table table-sm table-bordered text-center">
        <thead className="thead-light">
          <tr>
            <th colSpan={2}>{lastUpdate && `Updated ${lastUpdate}`}</th>
          </tr>
        </thead>
        <tbody>
          {fuelPrices.length ? fuelPrices.map((f, i) => (
            <tr key={i}>
              <td width="50%" data-label="Fuel">{f.fuel}</td>
              <td width="50%" data-label="Price">RM{f.price}</td>
            </tr>
          )) : null}
          <tr>
            <td colSpan="2">
              Share gojbleh in your group chats
              <div className="mt-2">
                <span className="clickable" onClick={() => this.clickSocial('whatsapp')}>
                  <img className="social-icon" src="/icons8-whatsapp.png" alt="share-whatsapp" />
                </span>
                <span onClick={() => this.clickSocial('facebook')}>
                  <img className="social-icon" src="/icons8-facebook-messenger.png" alt="share-facebook-messenger" />
                </span>
                <span onClick={() => this.clickSocial('telegram')}>
                  <img className="social-icon" src="/icons8-telegram.png" alt="share-telegram" />
                </span>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <button className="btn-a2hs" onClick={() => this.addToHomeScreen()}>Create gojbleh shortcut on mobile</button>
            </td>
          </tr>
          <tr>
            <td colSpan="2"><span className="btn-a2hs" onClick={this.feedback}>Send feedback</span></td>
          </tr>
        </tbody>
      </table>
    )
  }
}
export default FuelTable