import React from "react";
import Chart from "react-apexcharts";
import moment from "../lib/moment";
import { startCase } from "lodash";

class TravelTimesChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartWidth: "100%",
      chartHeight: "100%",
      options: {
        title: {
          text: "",
          align: "center",
        },
        chart: {
          type: "bar",
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          width: "100%",
          height: "100%",
        },
        colors: [],
        dataLabels: {
          enabled: true,
          offsetY: 3,
          style: {
            colors: ["#000000"],
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "90%",
            barHeight: "100%",
            dataLabels: {
              position: "top",
            },
          },
        },
        xaxis: {
          categories: [
            "4am",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12pm",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12am",
            "1",
            "2",
            "3am",
          ],
        },
        yaxis: {
          show: false,
          min: 0,
          max: 210,
          tickAmount: 7,
        },
        grid: {
          row: {
            colors: ["#ffffff", "#f2f2f2"],
          },
          padding: {
            top: -30,
            right: 10,
            bottom: 0,
            left: 15,
          },
        },
        tooltip: {
          theme: "dark",
          fillSeriesColor: true,
          x: {
            show: false,
          },
          y: {
            // formatter: (value) => `${value} mins`,
            title: {
              formatter: function () {
                return "";
              },
            },
          },
          style: {
            fontSize: "1.5em",
          },
        },
        // states: {
        //   hover: {
        //     filter: {
        //       type: "none",
        //     },
        //   },
        //   active: {
        //     filter: {
        //       type: "none",
        //     },
        //   },
        // },
        noData: {
          text: "Loading...",
        },
      },
      series: [],
    };

    this.updateGraphDimensions = this.updateGraphDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateGraphDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateGraphDimensions);
  }

  updateGraphDimensions() {
    const screenWidthWithHeight95percent = [800, 970, 1600];
    let height = "100%";
    if (
      screenWidthWithHeight95percent.includes(window.innerWidth) ||
      window.innerWidth >= 1680
    ) {
      height = "95%";
    }

    this.setState({
      chartHeight: height,
      options: { ...this.state.options, chart: { height } },
    });
  }

  render() {
    const { place } = this.props;
    let { options, series } = this.state;

    const weekDay = startCase(
      moment.utc(place.graph.timestamp).format("ddd").toLowerCase()
    );
    const destination = place.name.split(" ")[2];

    const colors = [
      ({ value }) => {
        const colors = [
          "#fff000",
          "#ffea00",
          "#ffe400",
          "#ffde00",
          "#ffd900",
          "#ffd300",
          "#ffcd00",
          "#ffc700",
          "#ffc100",
          "#ffbb00",
          "#ffb500",
          "#ffb000",
          "#ffaa00",
          "#ffa400",
          "#ff9e00",
          "#ff9800",
          "#ff9200",
          "#ff8c00",
          "#ff8700",
          "#ff8100",
          "#ff7b00",
          "#ff7500",
          "#ff6f00",
          "#ff6900",
          "#ff6400",
          "#ff5e00",
          "#ff5800",
          "#ff5200",
          "#ff4c00",
          "#ff4600",
          "#ff4000",
          "#ff3b00",
          "#ff3500",
          "#ff2f00",
          "#ff2900",
          "#ff2300",
          "#ff1d00",
          "#ff1700",
          "#ff1200",
          "#ff0c00",
          "#ff0600",
          "#ff0000",
        ];

        let index;
        if (value % 5 !== 0) {
          index = Math.floor(value / 5);
        } else {
          index = Math.floor(value / 5) - 1;
        }

        index = index <= 41 ? index : 41;

        return colors[index];
      },
    ];

    const maxValueInSeries = Math.max(...place.graph);
    const maxYAxis =
      maxValueInSeries >= options.yaxis.max
        ? options.yaxis.max
        : maxValueInSeries;

    options = {
      ...options,
      title: {
        ...options.title,
        text: `mins to ${destination} last ${weekDay}`,
      },
      yaxis: {
        ...options.yaxis,
        max: maxYAxis,
      },
      colors,
    };
    series = [{ name: place.code, data: place.graph }];

    return (
      <Chart
        options={options}
        series={series}
        type="bar"
        width={this.state.chartWidth}
        height={this.state.chartHeight}
      />
    );
  }
}

export default TravelTimesChart;
