import React from "react";

export const DefaultImage = props => (
  <img
    className={`lazyImage__img ${props.isLoading && "darken"} cam-image`}
    src="/default.png"
    alt=""
  />
);

class CamImage extends React.Component {
  render() {
    const { index, isLoading, isCamDown, onLoadImage, src } = this.props;
    return (
      <>
        <img
          className={`lazyImage__img cam-image`}
          style={!isLoading ? {} : { display: "none" }}
          onLoad={e => onLoadImage(e, index)}
          crossOrigin="anonymous"
          src={src}
          alt=""
        />
        {isCamDown && (
          <div className="overlay">
            <span className="overlay-text">
              CAM <br /> DOWN
            </span>
          </div>
        )}
      </>
    );
  }
}

export default CamImage;
