import React from "react";
import moment from "moment";
const Timestamp = ({ data }) => {
  if (!data.source[0]) {
    return null;
  }

  if (!data.source[0].timestamp) {
    return null;
  }

  const diffTime = moment(data.source[0].timestamp).from(
    moment(data.servertime)
  );
  return (
    <div className="row">
      <div className="col cam-timestamp text-left">
        {data.name.replace(/,.*/, "")} cams {diffTime}
      </div>
    </div>
  );
};
export default Timestamp;
