import React from "react";
import moment from "moment-timezone";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const tz = "Asia/Singapore";
const allowClickInterval = 24 * 3600;
class UpdateButton extends React.Component {
  state = {
    title: "",
    message: "",
    show: false,
    refresh: false,
    spam: false
  };
  handleClick = key => {
    let lastUpdate = localStorage.lu;
    let { title, message, spam, refresh } = this.state;
    refresh = false;
    spam = false;
    const now = moment().unix();
    if (lastUpdate) {
      const last = JSON.parse(lastUpdate);
      if (now - last < allowClickInterval) {
        spam = true;
      }
    }
    if (spam) {
      title = "Woa lau...";
      message =
        "Inaccurate reports hurt everyone. Users who spam will be banned.";
    } else {
      const k1 = key.charAt(0) === "W" ? "Woodlands" : "Tuas";
      const k2 = key.charAt(1) === "L" ? "left" : "right";
      const k3 = key.charAt(2) === "N" ? "no" : "";
      title = key;
      message = `You passed ${k1} checkpoint on the ${k2} lane with ${k3} fuel check. Are you sure?`;
    }
    this.setState({ show: true, title, message, spam, refresh });
  };
  clickYes = async key => {
    this.props.ReactGA.event({
      category: "Confirm button",
      action: `${key} - Yes`
    });
    let { title, message } = this.state;
    title = "Swee lah!";
    message = "All users will see the latest time.";
    this.setState({ title, message, refresh: true });
  };
  clickNo = key => {
    this.props.ReactGA.event({
      category: "Confirm button",
      action: `${key} - No`
    });
    this.setState({ show: false });
  };
  clickOk = async key => {
    this.props.ReactGA.event({
      category: "Confirm button",
      action: `${key} - Ok`
    });
    if (this.state.refresh) {
      this.props.click(key);
      localStorage.lu = JSON.stringify(moment().unix());
    }
    this.setState({ show: false });
  };
  handleClose = key => {
    this.props.ReactGA.event({
      category: "Confirm button",
      action: `${key} - X`
    });
    this.setState({ show: false });
  };
  handleHide = key => {
    this.props.ReactGA.event({
      category: "Confirm button",
      action: `${key} - Outside modal`
    });
    this.setState({ show: false });
  };
  render() {
    const { id, name, time, update } = this.props;
    const { show, title, message, refresh, spam } = this.state;
    const key = id + name;
    let timeText,
      dateText = null;
    if (time[key] !== 0) {
      timeText = time[key]
        ? moment(time[key])
            .tz(tz)
            .format("hh:mma")
        : moment()
            .tz(tz)
            .format("hh:mma");
      dateText = time[key]
        ? moment(time[key])
            .tz(tz)
            .format("DD MMM")
        : moment()
            .tz(tz)
            .format("DD MMM");
    }
    let type = "primary";
    if (name === "LFC" || name === "RFC") {
      type = "danger";
    }
    if (name === "LNFC" || name === "RNFC") {
      type = "success";
    }

    if (update.indexOf(key) > -1) {
      return (
        <button
          className={`btn btn-block btn-${type} btn-timestamp`}
          type="button"
          disabled
        >
          <span className="spinner-border spinner-border-sm"></span>
          <span> Updating...</span>
          <div className="btn-ddmm"> &nbsp; </div>
        </button>
      );
    } else {
      return (
        <div>
          <button
            className={`btn btn-block btn-${type} btn-timestamp`}
            type="button"
            onClick={() => this.handleClick(key)}
          >
            {time[key] === 0 && <span>{`${key}`}</span>}
            {time[key] !== 0 && (
              <>
                <span>{`${key} @ ${timeText}`}</span>
                <div className="btn-ddmm">{dateText}</div>
              </>
            )}
          </button>

          <Modal show={show} onHide={() => this.handleHide(key)} centered>
            <Modal.Body className="text-center">
              <Modal.Title className="text-center">
                <span
                  className="clickable float-right"
                  onClick={() => this.handleClose(key)}
                >
                  &times;
                </span>{" "}
                {title || <br />}
              </Modal.Title>
              <hr />
              <div className="mt-4">{message}</div>
            </Modal.Body>

            {refresh || spam ? (
              <Modal.Footer className="justify-content-center">
                <Button
                  className="btn-xl"
                  variant="secondary"
                  onClick={() => this.clickOk(key)}
                >
                  {" "}
                  OK{" "}
                </Button>
              </Modal.Footer>
            ) : (
              <Modal.Footer className="justify-content-center">
                <Button
                  className="btn-xl"
                  variant="secondary"
                  onClick={() => this.clickNo(key)}
                >
                  {" "}
                  NO{" "}
                </Button>
                <Button
                  className="btn-xl"
                  variant="primary"
                  onClick={() => this.clickYes(key)}
                >
                  {" "}
                  YES{" "}
                </Button>
              </Modal.Footer>
            )}
          </Modal>
        </div>
      );
    }
  }
}
export default UpdateButton;
