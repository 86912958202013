import React from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import ReactGA from "react-ga";
import { GoogleApiWrapper } from "google-maps-react";
import firebase, { config, Auth, Firestore } from "../lib/firebase";
import a2hsService from "../lib/a2hs";
import moment from "../lib/moment";
import UpdateButton from "./UpdateButton";
import FuelTable from "./FuelTable";
import CamImageCarousel from "./CamImageCarousel";
import Timestamp from "./Timestamp";
// import A2HSdebug from './Homescreen'
// link: 'https://www.google.com/maps/dir/1.4404203,103.7682408/1.4703258,103.7672377/@1.4683019,103.7666909,698m/data=!3m1!1e3!4m2!4m1!3e0',
moment.relativeTimeThreshold("m", 60);
moment.relativeTimeThreshold("h", 24 * 26);
moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "seconds",
    m: "1 min",
    mm: "%d mins",
  },
});
const buttonLoadingTime = 500;
const camReloadInterval = 60; // 1 minute
const backendBaseURL = `${process.env.REACT_APP_BACKEND_URL}`;
const cloudFunctionsBaseURL = `https://${process.env.REACT_APP_FIREBASE_FUNCTION_REGION}-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net`;

class App extends React.Component {
  state = {
    now: parseInt(moment().format("x")),
    locations: [
      {
        id: "woodlands",
        name: `W'lands`,
        p1: {
          name: "SG to JB",
          code: "w-sg-to-jb",
          graph: [],
        },
        p2: {
          name: "JB to SG",
          code: "w-jb-to-sg",
          graph: [],
        },
        source: [],
      },
      {
        id: "tuas",
        name: "Tuas",
        p1: {
          name: "SG to JB",
          code: "t-sg-to-jb",
          graph: [],
        },
        p2: {
          name: "JB to SG",
          code: "t-jb-to-sg",
          graph: [],
        },
        source: [],
      },
    ],
    timestamps: {},
    btnLoading: [],
    message: "Okay",
    fuelPrices: [],
    lastUpdate: "",
  };
  constructor(props) {
    super(props);
    ReactGA.initialize(process.env.REACT_APP_TRACK_ID);
    ReactGA.pageview("/");
    this.a2hsInit();
  }
  a2hsInit = () => {
    this.a2hs = new a2hsService();
    this.a2hs.checkUserAgent();
    this.a2hs.trackStandalone();
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.a2hs.promptIntercepted = true;
      this.a2hs.promptSaved = true;
      this.a2hs.deferredPrompt = e;
      if (
        (window.matchMedia &&
          window.matchMedia("(display-mode: standalone)").matches) ||
        window.navigator.standalone === true
      ) {
        return false;
      }
    });
    window.addEventListener("appinstalled", (evt) => {
      this.a2hs.trackInstalled();
      this.a2hs.promptIntercepted = false;
    });
  };
  componentDidMount = async () => {
    await Auth.signInWithEmailAndPassword(
      config.auth.email,
      config.auth.password
    );
    await Auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.onBlur);
    this.onFocus();
  };
  componentWillUnmount = async () => {
    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("blur", this.onBlur);
    await Auth.signOut();
    this.onBlur();
  };
  onFocus = async () => {
    this.getImages();
    this.getMatrix();
    this.imageTimer = setInterval(async () => {
      this.getImages();
    }, camReloadInterval * 1000);
    this.getTimestamps();
    this.getFuelPrices();
    this.getGraphs();
  };
  onBlur = async () => {
    clearInterval(this.imageTimer);
    if (this.unregTimestamps) {
      this.unregTimestamps();
    }
  };
  getServerTime = async () => {
    const timeURL = `${cloudFunctionsBaseURL}/time`;
    const { data } = await axios.get(timeURL);
    this.setState({ now: data });
    return data;
  };
  getImages = async () => {
    const now = await this.getServerTime();
    const { locations } = this.state;

    const response = await axios.get(`${backendBaseURL}/sources`);
    if (response.status !== 200) {
      console.error("Failed to fetch sources");
      return;
    }

    const sources = response.data;
    locations.map((location) => {
      location.source = sources[location.id];
      location.servertime = moment(now).format();
      return location;
    });
    this.setState({ locations });
  };

  getMatrix = async () => {
    const { locations } = this.state;
    const { docs } = await Firestore.collection("distancematrix").get();
    let rawDocs = {};
    for (let i = 0; i < docs.length; i++) {
      const d = docs[i];
      rawDocs[d.id] = d.data().value;
    }

    locations.map((l) => {
      if (l.p1) {
        const doc1 = l.p1.code;
        const doc2 = l.p2.code;
        l.p1.dm = rawDocs[doc1];
        l.p2.dm = rawDocs[doc2];
      }
      return l;
    });
    this.setState({ locations });
  };

  getTimestamps = async () => {
    let { locations, timestamps } = this.state;
    const prevTimestamps = timestamps;
    this.unregTimestamps = Firestore.collection("timestamps").onSnapshot(
      async (response) => {
        let timedata = {};
        for (let i = 0; i < response.docs.length; i++) {
          const doc = response.docs[i];
          if (doc.id !== "server-time") {
            timedata[doc.id] = doc.data().value;
          }
        }
        const btnLoading = Object.keys(timedata).filter(
          (t) => timedata[t] !== timestamps[t]
        );
        locations.length &&
          locations.map(async (l) => {
            const LFC = `${l.name.charAt(0)}LFC`;
            const RFC = `${l.name.charAt(0)}RFC`;
            const LNFC = `${l.name.charAt(0)}LNFC`;
            const RNFC = `${l.name.charAt(0)}RNFC`;
            timestamps[LFC] = timedata[LFC] || prevTimestamps[LFC] || 0;
            timestamps[RFC] = timedata[RFC] || prevTimestamps[RFC] || 0;
            timestamps[LNFC] = timedata[LNFC] || prevTimestamps[LNFC] || 0;
            timestamps[RNFC] = timedata[RNFC] || prevTimestamps[RNFC] || 0;
          });
        // console.log('receive update..', btnLoading)
        this.setState({ timestamps, btnLoading });
        setTimeout(async () => {
          this.setState({ btnLoading: [] });
        }, buttonLoadingTime);
      }
    );
  };
  updateTimestamps = async (key) => {
    const now = await this.getServerTime();
    if (now) {
      ReactGA.event({
        category: "Update Button",
        action: `${key} clicked at ${now}`,
      });
      // console.log('sending update..', key)
      try {
        Firestore.collection("timestamps").doc(key).set({ value: now });
      } catch (error) {
        console.error("error update timestamps..", error);
      }
    }
  };
  getFuelPrices = async () => {
    let { lastUpdate } = this.state;
    const fuelPrices = [];
    const { docs } = await Firestore.collection("fuelprices").get();
    for (let i = 0; i < docs.length; i++) {
      const d = docs[i];
      if (d.id === "lastUpdate") {
        lastUpdate = d.data().value;
      } else {
        fuelPrices.push(d.data());
      }
    }
    fuelPrices.sort((a, b) => a.id - b.id);
    this.setState({ lastUpdate, fuelPrices });
  };

  getGraphs = async () => {
    let { locations } = this.state;
    const response = await axios.get(`${backendBaseURL}/graphs`);
    if (response.status !== 200) {
      console.error("Failed to fetch sources");
      return;
    }

    locations.map((location) => {
      location.p1.graph = response.data[location.p1.code].series;
      location.p2.graph = response.data[location.p2.code].series;
      return location;
    });
    this.setState({ locations });
  };

  render() {
    let {
      locations,
      timestamps,
      btnLoading,
      fuelPrices,
      lastUpdate,
    } = this.state;
    return (
      <>
        <Helmet>
          <meta
            http-equiv="Content-Security-Policy"
            content="upgrade-insecure-requests"
          />
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            {locations.length &&
              locations.map((l, i) => {
                const id = l.name.charAt(0);
                return (
                  <div className="col-lg-6" key={i}>
                    <Timestamp data={l} />
                    <CamImageCarousel id={id} location={l} ReactGA={ReactGA} />
                    <div className="row">
                      <div className="col button">
                        <UpdateButton
                          name="LFC"
                          id={id}
                          update={btnLoading}
                          time={timestamps}
                          click={this.updateTimestamps}
                          ReactGA={ReactGA}
                        />
                      </div>
                      <div className="col button">
                        <UpdateButton
                          name="RFC"
                          id={id}
                          update={btnLoading}
                          time={timestamps}
                          click={this.updateTimestamps}
                          ReactGA={ReactGA}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col button">
                        <UpdateButton
                          name="LNFC"
                          id={id}
                          update={btnLoading}
                          time={timestamps}
                          click={this.updateTimestamps}
                          ReactGA={ReactGA}
                        />
                      </div>
                      <div className="col button">
                        <UpdateButton
                          name="RNFC"
                          id={id}
                          update={btnLoading}
                          time={timestamps}
                          click={this.updateTimestamps}
                          ReactGA={ReactGA}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="row">
            <FuelTable
              fuelPrices={fuelPrices}
              lastUpdate={lastUpdate}
              a2hs={this.a2hs}
              ReactGA={ReactGA}
            />
          </div>
          {/* <br /> <A2HSdebug a2hs={this.a2hs} /> */}
        </div>
      </>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_API_KEY,
})(App);
