import React from "react";

export default (props) => (
  <div className={`label-${props.id} ${props.orderClass}`}>
    <label className="badge badge-light">
      <span>
        <img src="/iconfinder-google-favicon.svg" alt="google-favicon" />
        {`${props.duration} to ${props.destination}`}
      </span>
    </label>
  </div>
);
